import { SidebarItemsType } from '../../types/sidebar';

import { Layout, BarChart2, Link } from 'react-feather';

const pagesSection = [
    {
        href: '/',
        icon: Layout,
        title: 'Summary',
    },
] as SidebarItemsType[];

const elementsSection = [
    {
        href: '/details',
        icon: BarChart2,
        title: 'Details / Export',
    },
] as SidebarItemsType[];

const referencesSection = [
    {
        href: '/references',
        icon: Link,
        title: '個別債券情報',
    },
] as SidebarItemsType[];

const navItems = [
    {
        title: 'Summary',
        pages: pagesSection,
    },
    {
        title: 'details',
        pages: elementsSection,
    },
    {
        title: 'references',
        pages: referencesSection,
    },
];

export default navItems;
