import * as React from 'react';
import styled from '@emotion/styled';

import { Grid, IconButton as MuiIconButton, Toolbar } from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

interface NavbarProps {
    onDrawerToggle: React.MouseEventHandler<HTMLElement>;
}

function Navbar({ onDrawerToggle }: NavbarProps) {
    return (
        <React.Fragment>
            <Toolbar>
                <Grid container alignItems="center" sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                        <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle} size="large">
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Toolbar>
        </React.Fragment>
    );
}

export default Navbar;
