import React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import Image from 'next/image';

import { Drawer as MuiDrawer, ListItemButton } from '@mui/material';
import { SidebarItemsType } from '../../types/sidebar';
import SidebarNav from './SidebarNav';

const Drawer = styled(MuiDrawer)`
    border-right: 0;

    > div {
        border-right: 0;
    }
`;

const Brand = styled(ListItemButton)`
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.sidebar.header.color};
    background-color: ${(props) => props.theme.sidebar.header.background};
    font-family: ${(props) => props.theme.typography.fontFamily};
    justify-content: center;
    cursor: pointer;
    flex-grow: 0;
    min-height: 56px;
    padding-left: ${(props) => props.theme.spacing(6)};
    padding-right: ${(props) => props.theme.spacing(6)};
    &:hover {
        background-color: ${(props) => props.theme.sidebar.header.background};
    }
`;

export interface SidebarProps {
    PaperProps: {
        style: {
            width: number;
        };
    };
    variant?: 'permanent' | 'persistent' | 'temporary';
    open?: boolean;
    onClose?: () => void;
    items: {
        title: string;
        pages: SidebarItemsType[];
    }[];
}

const Sidebar = ({ items, ...rest }: SidebarProps) => {
    return (
        <Drawer variant="permanent" {...rest}>
            <Link href="/">
                <Brand>
                    <Image src="/gdtb-logo.png" width={200} height={70} objectFit={'contain'} />
                </Brand>
            </Link>
            <SidebarNav items={items} />
        </Drawer>
    );
};

export default Sidebar;
