import * as React from 'react';

import { Grid, Typography } from '@mui/material';

function Footer() {
    return (
        <Grid container item xs={12} justifyContent="center" my={4}>
            <Typography variant="caption">{`© ${new Date().getFullYear()} - JPX Market Innovation & Research, Inc.`}</Typography>
        </Grid>
    );
}

export default Footer;
